import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ParkingWidget from "./components/ParkingWidget";
import PageLoader from '../App/components/PageLoader';
import './styles.css';

export default function Dashboard() {
    const { parkings } = useSelector((state) => state.dashboard);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        console.log('dash', parkings)
        setLoading(false);
    }, [parkings]);

    function getComponent() {
        if (parkings.length > 0)
            return parkings.map((item) => (
                <Grid item xs={12} xl={10} sm={6} md={4} key={item.client}>
                    <ParkingWidget data={item} />
                </Grid>
            ))

        return <Typography variant="h6">Não há estabelecimento conectado</Typography>
    }

    return (
        <Grid container spacing={3}>
            {!loading ? getComponent() : <PageLoader />}
        </Grid>
    );
}