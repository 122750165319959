import { combineReducers } from 'redux';

import { appReducer } from '../AppReducer';
import { dashboardReducer } from '../../Dashboard/DashboardReducer';

const Reducers = combineReducers({
    app: appReducer,
    dashboard: dashboardReducer
});

export default Reducers;