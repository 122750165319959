import clsx from "clsx";
import React from "react";
import { useDispatch } from 'react-redux';
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ArrowForward from "@material-ui/icons/ArrowForward";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import useStyles from "../hooks/useStyles";
import useMozartColors from "../hooks/useMozartColors";
import ParkingDetails from "../../Dashboard/components/ParkingDetails";
import { closeParkingDetails } from "../AppActions"

const MozartDrawer = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const mozartColors = useMozartColors();

    function handleCloseDrawer() {
        dispatch(closeParkingDetails());
    }

    return (
        <Drawer
            variant="permanent"
            className={clsx(classes.drawer)}
            anchor="right"
        >
            <div className={classes.toolbar} />
            <Divider />
            <div>
                <List>
                    <ListItem button onClick={handleCloseDrawer}>
                        <ListItemIcon><ArrowForward style={{ color: mozartColors.greenCS }} /></ListItemIcon>
                        <ListItemText primary='Fechar' />
                    </ListItem>
                </List>
                <Divider />
                <ParkingDetails />
            </div>
        </Drawer>
    );
}

export default MozartDrawer;