import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { setSelectParking, setResultSet } from '../DashboardActions';
import { SocketSubmitService } from '../../App/services/SocketSubmitService'

export default function ParkingWidget({ data }) {
    const { open } = useSelector((state) => state.app);
    const useStyles = makeStyles({
        root: {
            minWidth: open ? 245 : 260,
            // maxWidth: 275,
        },
        titleContent: {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 10
        },
        alertIcon: {
            height: 30,
            width: 30,
            borderRadius: 15,
            marginRight: 10,
            // background: getColor(props.data.status)
            background: '#0cff00'
        },
        description: {
            display: 'flex',

            justifyContent: 'space-between'
        },
        values: {
            textAlign: 'center'
        },
        alertText: {
            color: '#d9534f'
        }
    });
    const classes = useStyles();
    const dispatch = useDispatch();

    // function getColor(type) {
    //     switch (String(type)) {
    //         case 'success': return '#0cff00'
    //         case 'warning': return '#fff600'
    //         case 'danger': return '#d9534f'
    //         default: return '#666'
    //     }
    // }

    function handleSelectParking() {
        dispatch(setResultSet(''));
        dispatch(setSelectParking(data));
        SocketSubmitService.joinRoom(data.client);
    }

    return (
        <Card className={classes.root} variant="outlined">
            <CardContent>
                <div className={classes.titleContent}>
                    <Card className={classes.alertIcon} />
                    <Typography variant="h6">{data.client}</Typography>
                </div>
                <div className={classes.description}>
                    <Typography variant="body2">IP</Typography>
                    <Typography variant="body2">{data.status.ip}</Typography>
                </div>
            </CardContent>
            {/* <div className={classes.values}>
                <Typography variant="body2" className={classes.alertText}>{props.data.alert}</Typography>
            </div> */}
            <CardActions>
                <Button size="small" onClick={handleSelectParking}>Mais detalhes</Button>
            </CardActions>
        </Card>
    );
}