export const OPEN_DRAWER = 'OPEN_DRAWER';
export const CLOSE_DRAWER = 'CLOSE_DRAWER';

const INITIAL_STATE = {
    open: false
}

export function appReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case OPEN_DRAWER:
            return {
                ...state,
                open: true
            }
        case CLOSE_DRAWER:
            return {
                ...state,
                open: false
            }
        default:
            return state
    }
}