import React, {useEffect, useState} from 'react';
import { useHistory } from "react-router";
import { useSelector } from 'react-redux';
import {useTheme} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { AccountCircle } from "@material-ui/icons";
import useStyles from "./hooks/useStyles";
import MozartDrawer from "./components/MozartDrawer";
import StorageService from "./services/StorageService";
import { SocketOnService } from './services/SocketOnService';

function App(props) {
    const { open } = useSelector((state) => state.app);
    const history = useHistory();
    const classes = useStyles();
    const theme = useTheme();
    const [userName, setUserName] = useState('false');
    const [anchorEl, setAnchorEl] = useState(null);
    const isMenuOpen = Boolean(anchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    
    useEffect(() => {
        SocketOnService.connect();
        setUserName(StorageService.get('name'));
    }, []);

    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={'primary-search-account-menu'}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            {/* <MenuItem onClick={handleMenuClose}>Alterar Senha</MenuItem>
            <MenuItem onClick={handleMenuClose}>Minha Conta</MenuItem> */}
            <MenuItem onClick={() => {
                if (window.confirm('Encerrar sessão?')) {
                    SocketOnService.disconnect();
                    window.localStorage.removeItem('token');
                    window.localStorage.removeItem('name');
                    history.push('/');
                }
            }}>Sair</MenuItem>
        </Menu>
    );

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <AppBar
                position="fixed"
                className={classes.appBar}
            >
                <Toolbar>
                    <Typography variant="h6"   style={{ flex: 1 }}>Mozart DM</Typography>
                    <Typography>{userName}</Typography>
                    <div className={classes.sectionDesktop}>
                        <IconButton
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={'primary-search-account-menu'}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                    </div>
                </Toolbar>
                {renderMenu}
            </AppBar>

            <main className={classes.content}>
                <div className={classes.toolbar}/>
                {props.children}
            </main>

            {open && (
                <MozartDrawer theme={theme} />
            )}
        </div>
    );
}


export default App;
