import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import './styles.css';

export default function ParkingDetailsOptions(props) {
    const { title, onClickButton, buttonText } = props.data;
    return (
        <div className="description">
            <Typography variant="body1">{title}</Typography>
            <div className="buttons">
                <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={() => { onClickButton() }}
                >{buttonText}</Button>
            </div>
        </div>
    );
}