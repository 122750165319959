import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { SocketSubmitService } from '../../App/services/SocketSubmitService';
import ParkingDetailsOptions from './ParkingDetailsOptions';
import './styles.css';

export default function ParkingDetails() {
    const { selectParking, resultSet } = useSelector((state) => state.dashboard);
    const useStyles = makeStyles({
        alertIcon: {
            height: 30,
            width: 30,
            borderRadius: 15,
            marginRight: 10,
            background: getColor(selectParking.status)
        },
    });
    const classes = useStyles();

    const options = [
        {
            id: 1,
            title: 'IP',
            buttonText: 'Teste',
            onClickButton: () => { SocketSubmitService.ipconfig(selectParking.client) }
        },
        {
            id: 2,
            title: 'Temperatura',
            buttonText: 'Teste',
            onClickButton: () => { SocketSubmitService.temperature(selectParking.client) }
        },
        {
            id: 3,
            title: 'Diskspace',
            buttonText: 'Teste',
            onClickButton: () => { SocketSubmitService.diskspace(selectParking.client) }
        },
        {
            id: 4,
            title: 'API',
            buttonText: 'Teste',
            onClickButton: () => { SocketSubmitService.pingapief(selectParking.client) }
        },
        {
            id: 5,
            title: 'Detector',
            buttonText: 'Teste',
            onClickButton: () => { SocketSubmitService.pingdetector(selectParking.client) }
        },
        {
            id: 6,
            title: 'Bee',
            buttonText: 'Teste',
            onClickButton: () => { SocketSubmitService.pingbee(selectParking.client) }
        },
        {
            id: 7,
            title: 'Camera',
            buttonText: 'Teste',
            onClickButton: () => { SocketSubmitService.pingcameras(selectParking.client) }
        }
    ]

    function getColor(type) {
        switch (String(type)) {
            case 'success': return '#0cff00'
            case 'warning': return '#fff600'
            case 'danger': return '#d9534f'
            default: return '#0cff00'
        }
    }

    return (
        <div className="root">
            <div className="titleContent">
                <Card className={classes.alertIcon} />
                <Typography variant="h5">{selectParking.client}</Typography>
            </div>
            {/* <div className="description">
                <Typography variant="body2">Temperatura</Typography>
                <Typography variant="body2">33</Typography>
            </div> */}

            {/* <div className="alert">
                <Typography variant="body2" className="alertText">{selectParking.alert}</Typography>
            </div> */}

            {options.map((item) => (
                <ParkingDetailsOptions key={item.id} data={item} />
            ))}

            {resultSet && (
                <>
                    <div className="div-line-content">
                        <div className="div-line" />
                    </div>

                    <div className="result-content">
                        <Typography variant="body1">Resultado:</Typography>
                        {resultSet.split('\n').map((str, index) => <p key={index}>{str}</p>)}
                    </div>
                </>
            )}
        </div>
    );
}