import { OPEN_DRAWER, CLOSE_DRAWER } from './AppReducer.js';

export function openParkingDetails(value) {
    return dispatch => {
        dispatch({
            type: OPEN_DRAWER
        });
    };
}

export function closeParkingDetails(modal) {
    return dispatch => {
        dispatch({
            type: CLOSE_DRAWER
        });
    };
}