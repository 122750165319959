import { io } from "socket.io-client";
import { store } from '../store';
import { setParking, setResultSet } from '../../Dashboard/DashboardActions';

export const socket = io("https://sauron-server.consforsistemas.com.br?id=3121&type=DASHBOARD&user=12333123&auth=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiYWRtaW4iLCJpYXQiOjE2MjQyODgyNzF9.BSJEN9Hokr2lsiXY27dxM5AjI_oeO-MJYvhBOAyLcTE", {
    cors: {
        origin: "*",
        methods: ["GET", "POST"]
    }
});


let offlineService = true;
let firstConnection = true;
export const SocketOnService = {
    connect: (data) => {
        socket.on('connected', function () {
            console.log("Canal aberto")
            if (firstConnection === true) {
                //get updates
            }
            offlineService = false;
            firstConnection = false;
        });
        socket.on('new_client', function (data) {
            console.log("Novo dispositivo conectado", data)
            console.log(data);
        });

        socket.on('status_update', function (data) {
            console.log("status update");
            console.log(data);
            store.dispatch(setParking(data))


            // if (document.getElementById("client_" + data.client) == null) {
            //     var tr = document.createElement("tr");
            //     var td = document.createElement("td");
            //     tr.id = 'client_' + data.client;
            //     tr.setAttribute("id_cli", data.client);
            //     td.innerText = data.client;
            //     tr.append(td);
            //     tr.addEventListener('click', function () {
            //         idCli = this.getAttribute("id_cli");
            //         document.getElementById("client-join").value = idCli
            //         document.getElementById("btn-join").dispatchEvent(new Event('click'));
            //     })

            //     document.getElementById("clients").append(tr);
            // }
        });

        socket.on('refresh_page', function (data) {
            window.location.reload();
        });

        socket.on('disconnect', function () {
            console.log('server disconnected');
            offlineService = true;
        });

        //commands report
        socket.on('status_report', function (data) {
            console.log('status_report');
            console.log(data);
            console.log(data.result);
            printLog(data.result);
        });
        socket.on('pingapief_report', function (data) {
            console.log('pingapief_report');
            console.log(data);
            printLog(data.result);
        });
        socket.on('pingdetector_report', function (data) {
            console.log('pingdetector_report');
            console.log(data);
            printLog(data.result);
        });
        socket.on('pingbee_report', function (data) {
            console.log('pingbee_report');
            console.log(data);
            printLog(data.result);
        });
        socket.on('pingcameras_report', function (data) {
            console.log('pingcameras_report');
            console.log(data);
            console.log(data.result);

            let text = "";
            Object.entries(data.result).forEach(function (value, key) {
                text += value[0] + " - " + value[1] + "\n";
            })

            printLog(text);
        });
        socket.on('ipconfig_report', function (data) {
            console.log('ipconfig_report');
            console.log(data);
            printLog(data.result);
        });
        socket.on('diskspace_report', function (data) {
            console.log('diskspace_report');
            console.log(data);
            printLog(data.result);
        });
        socket.on('temperature_report', function (data) {
            console.log('temperature_report');
            console.log(data);
            printLog(data.result);
        });
    },
    disconnect: () => {
        socket.on('disconnect', function () {
            console.log('server disconnected');
            offlineService = true;
        });
    }
}

async function printLog(text) {
    console.log('print',text);
    // let newText = await escapeHtml(text);
    // let finalText = await text.replaceAll("<br>", '\n').replaceAll("&nbsp", " ")
    store.dispatch(setResultSet(text));
}

// function escapeHtml(unsafe) {
//     return unsafe
//         .replaceAll(/&/g, "&amp;")
//         .replaceAll(/</g, "&lt;")
//         .replaceAll(/>/g, "&gt;")
//         .replaceAll(/"/g, "&quot;")
//         .replaceAll(/'/g, "&#039;");
// }