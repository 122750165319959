import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Router, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Provider } from 'react-redux';

import AuthenticatedRouter from "./App/AuthenticatedRouter";
import Login from "./Login/Login";
import Dashboard from "./Dashboard";
import { store } from './App/store';

const history = createBrowserHistory();

ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            <BrowserRouter>
                <Switch>
                    <Route path="/" exact component={Login} />
                    <AuthenticatedRouter path="/Dashboard" component={Dashboard} />
                    <Route path="*" component={() => <h1>Page not found</h1>} />
                </Switch>
            </BrowserRouter>
        </Router>
    </Provider>,
    document.getElementById('root')
);
