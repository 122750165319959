import React from "react";
import { LinearProgress, Paper} from "@material-ui/core";

const PageLoader = () => {

    return <Paper style={{
        textAlign: "center",
        fontSize: '20px',
        padding: '100px 10px',
        border: 'none',
        background: 'transparent',
    }}>
        <LinearProgress />
        {/*<LinearProgress color="secondary" />*/}
        <div style={{marginTop: `40px`}}>Carregando... Por favor, aguarde =]</div>
    </Paper>
}

export default PageLoader;