import { SET_PARKING, SET_SELECT_PARKING, SET_RESULTSET } from './DashboardReducer';
import { openParkingDetails } from '../App/AppActions'

export function setSelectParking(value) {
    return dispatch => {
        dispatch({
            type: SET_SELECT_PARKING,
            payload: value
        });

        dispatch(openParkingDetails());
    };
}

export function setParking(value) {
    return dispatch => {
        dispatch({
            type: SET_PARKING,
            payload: value
        });
    };
}

export function setResultSet(value) {
    return dispatch => {
        dispatch({
            type: SET_RESULTSET,
            payload: value
        });
    };
}