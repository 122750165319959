export const SET_PARKING = 'SET_PARKING';
export const SET_SELECT_PARKING = 'SET_SELECT_PARKING';
export const SET_RESULTSET = 'SET_RESULTSET';

const INITIAL_STATE = {
    parkings: [],
    selectParking: {},
    resultSet: ''
}

export function dashboardReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_PARKING:
            let newData
            const exist = state.parkings.filter(item => item.client === action.payload.client);

            if (exist.length > 0) {
                newData = state.parkings.map((item) => {
                    if (item.client === action.payload.client)
                        return action.payload
                    return item
                })
            } else {
                newData = [...state.parkings, action.payload]
            }

            return {
                ...state,
                parkings: newData
            }
        case SET_SELECT_PARKING:
            return {
                ...state,
                selectParking: action.payload
            }
        case SET_RESULTSET:
            return {
                ...state,
                resultSet: action.payload
            }
        default:
            return state
    }
}