import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Hidden from '@material-ui/core/Hidden';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import aiImg from './loginImage.jpeg';
import './styles.css';
// import ApiService from "../App/services/ApiService";
import StorageService from "../App/services/StorageService";
import Loading from "../App/components/Loading";

const useStyles = makeStyles({
    textField: {
        width: '30ch',
    },
    contSize: {
        width: '100%',
        height: 'auto',
        alignItems: 'center',
        margin: '0',
        padding: '0',
    },
});

export default function Login() {
    const classes = useStyles();
    const history = useHistory();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(false);
    }, [])

    function handleToLogin(e) {
        e.preventDefault();
        setLoading(true);

        if (username === '' || password === '') {
            setLoading(false);
            return alert('Insira os campos corretamente');
        }
        
        const resultSet = {
            token: 'soakposkpaosakopsks',
            name: 'Gabriele'
        }

        // ApiService.get('v1/auth', { username, password }).then(response => {
        //     console.log(`response`,response)
        //     if (response.status !== 200) {
        //         setLoading(false);
        //         return alert('Usuário e/ou senha incorreta');
        //     }
            // const resultSet = response.resultSet[0];
            StorageService.add('token', resultSet['token']);
            StorageService.add('name', resultSet['name']);
            history.push('/dashboard');
        // })
    }

    return (
        <Grid container>
            <Hidden smDown >
                <Grid item xs={false} sm={false} md={6} lg={8} xl={8} className="img-container" >
                    <img src={aiImg} alt="Artificial Intelligence" />
                </Grid>
            </Hidden>

            <Grid item sm={12} md={6} lg={4} xl={4} className="form-container">
                <Typography variant="h4">Monitoramento</Typography>

                <Grid item className="account" >
                    <TextField
                        id="standard-required"
                        label="Usuário"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        className={classes.textField}
                        required
                    />
                </Grid>

                <Grid item justify="center" container className="password" >
                    <FormControl className={classes.textField} required>
                        <InputLabel htmlFor="standard-adornment-password">Senha</InputLabel>
                        <Input
                            id="standard-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                        onMouseDown={(e) => e.preventDefault()}
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Grid>

                <button onClick={handleToLogin} disabled={loading} >{loading ? <Loading /> : 'ENTRAR'}</button>
            </Grid>
        </Grid>
    );
}