import { socket } from './SocketOnService';

export const SocketSubmitService = {
    joinRoom: (client) => {
        socket.emit("join_client_room", { client: client });
    },
    leftRoom: (client) => {
        socket.emit("left_client_room", { client: client })
    },
    ipconfig: (client) => {
        socket.emit("get_ipconfig", { client: client })
    },
    temperature: (client) => {
        socket.emit("get_temperature", { client: client });
    },
    diskspace: (client) => {
        socket.emit("get_diskspace", { client: client });
    },
    pingapief: (client) => {
        socket.emit('get_pingapief', { client: client });
    },
    pingdetector: (client) => {
        socket.emit('get_pingdetector', { client: client });
    },
    pingbee: (client) => {
        socket.emit('get_pingbee', { client: client });
    },
    pingcameras: (client) => {
        socket.emit('get_pingcameras', { client: client });
    },
}
